<template lang="pug">
  .competitors-data
    .rental-period(
      v-for="({ value: rentalPeriod }) in rentalPeriods"
      :key="rentalPeriod"
    )
      .rental-period-head
      CompetitorRow(
        v-for="competitor in competitors"
        :key="competitor.id"
        :competitor="competitor"
        :formatted-dates="formattedDates"
        :rental-period="rentalPeriod"
      )
</template>

<script>
  import { map, get } from "lodash-es"
  import { getFormattedDate } from "@/helpers/dates"

  export default {
    components: {
      CompetitorRow: () => import("./CompetitorRow")
    },

    props: {
      competitors: Array,
      rentalPeriods: Array,
      dateRange: Array
    },

    computed: {
      formattedDates() {
        return map(this.dateRange, date => getFormattedDate(date))
      }
    },

    methods: {
      getPrice(competitor, rentalPeriod, date) {
        return get(competitor, `prices.${rentalPeriod}.${date}`)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .competitors-data
    .car-class-name
      align-items: center
      display: flex
      height: 41px

    .rental-period
      &-head
        border-bottom: 1px solid $border-element-color
        height: 40px
</style>
